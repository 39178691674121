body {
	padding-top: 90px;
	&.no-scroll {
		position: relative;
		overflow: hidden;
	}
}

.alert {
	border-radius: 0;
}