.faq__question {
	cursor: pointer;
	transition: color 0.1s ease-in-out;
	&.active {
		color: $secondary;
		font-weight: bold;
	}
}

.faq__answer {
	height: 0;
	overflow: hidden;
	&.active {
		height: auto;
	}
}

.faq__toggle {
	position: absolute;
	right: 15px;
	top: 0;
	transform: rotate(180deg);
	transition: all 0.1s ease-in-out;

	&.active {
		transform: rotate(0);
	}
}