h1,h2,h3,h4,h5,h6 {
	font-weight: normal;
	color: #242424;
	position: relative;
}

.title--styled {
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		width: 60px;
		height: 3px;
		background-color: $primary;
		left: 0;
	}
}


.text-small {

	* {
		font-size: 1rem !important;
	}
	
}

.organisatie {
	p {
		margin-bottom: 8px;
		strong {
			margin-top: 24px;
			margin-bottom: 24px;
			display: block;
			height: 20px;
		}
	}
}

body, p {
	color: #242424;
}