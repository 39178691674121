

.row--styled {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 76%;
		height: 56%;
		right: 0%;
		background-color: $med-light-grey;
		z-index: -1;
	}
}

// .organisatie {
// 	p {
// 		font-size: 18px;
// 	}
// }