.intro-block {
	box-shadow: 0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);

	h2, h3, h4, h5 {
		margin-bottom: 24px;
	}

	p {
		padding: 0 32px 0 32px;
		color: #6c757d;
	}
}