.text-shadow {
	text-shadow: 2px 2px 2px rgba($dark-grey, 0.6);
}

.text-shadow-md {
	text-shadow: 1.4px 1.4px 1.4px rgba($dark-grey, 0.8);
}

.block__content {
	p {
		font-size: 1.25rem;
	    font-weight: 300;
	}
}

strong {
	font-weight: bold;
}