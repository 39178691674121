.news__tile {
	background: #fff;
	box-shadow: 0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);
	transition: all .2s ease-in-out;

	&:hover {
		box-shadow: 0 50px 100px rgba(50,50,93,.2), 0 15px 35px rgba(50,50,93,.25), 0 5px 15px rgba(0,0,0,.2);

	}

	h4 {
		min-height: 84px;
	}

	p {
		min-height: 72px;
	}
}