$border-radius : 4px;

$primary:       #2f3e96;
$secondary:     #d61e5d;
$primary-dark: #010e28;
$off-white: #f4f3fb;

$light-grey: #f6f6f6;
$med-light-grey: #f3f3f3;
$med-grey: #cecece;
$dark-grey: #a5a5a5;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

$font-family-base : 'Ubuntu', sans-serif;
