.profile-overlay {
	position: fixed;
	left:0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	background-color: rgba(0,0,0,0.6);
	display: none;

	&.visible {
		display: block;
	}
}

.profile-img {
	cursor: pointer;
	min-height: 380px;
	position: relative;
	overflow: hidden;
	transition: all 0.3s ease;
	border: 4px solid #fff;
	background-repeat: no-repeat;

	@media screen and (max-width: 768px) {
		background-size: cover;
	}

	  &:before {
	    content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to bottom right,transparent, $primary);
		opacity: .4; 
		transition: all 0.3s ease;
	  }


	.profile-content {
		top: 286px;
		position: absolute; 
		transition: all 0.3s ease;
	}

	&:hover {

	  &:before {

		opacity: .2; 
	  }

		.profile-content {
			top: 226px;
			position: absolute; 
		}
	}

}

.profile-description {
	opacity: 0;
	width: 66vw;
	height: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: #fff;
	position: fixed;
	z-index: 1001;
	overflow: hidden;
	-webkit-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.57);
	box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.57);
	transition: opacity 0.2s ease-in-out;


	&.visible {
		opacity: 1;
		height: auto;
		max-height: 60vh;

		@media screen and (max-width: 768px) {
			width: 100vw;
			max-height: 100vh;
		}
	}
}

.profile-modal-close {
	margin: 1rem 1rem 1rem auto;
	cursor: pointer;
	float: right;
	outline: none;
	border: none;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background-color: $secondary;
	text-align: center;
	transition: all 0.2s ease-in-out;
	z-index: 9999;
	position: relative;

	&:hover {
		background-color: darken($secondary, 8%);
	}

	img {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 20px;
		height: 20px;
		margin-left: 10px;
		float: none;
		display: block;
	}
}