.btn {
	border-radius: 4px;

	@media screen and (max-width: 768px) {
		display: block;
		width: 100%;

		&.truncate {
		  width: 100%;
		  white-space: nowrap;
		  overflow: hidden;
		  text-overflow: ellipsis;
		}

	}
}

.btn-default {
	background-color : $med-grey;

	&:hover {
		background-color : darken($med-grey, 5%);
	}
}